import { Injectable } from '@angular/core';

import { PermissionDto, PermissionResultDto } from '../dtos/permission.dto';
import { Permission } from '../models/permission';

import { MapperFromDto } from './mappers';

/** Permission mapper. */
@Injectable({
	providedIn: 'root',
})
export class PermissionMapper implements MapperFromDto<PermissionDto, Permission> {

	/** @inheritdoc */
	public fromDto(dto: PermissionDto): Permission {
		return {
			id: dto.id,
			name: dto.name,
			description: dto.description,
		};
	}

	/**
	 * Maps permission result from DTO.
	 * @param dto Dto data.
	 */
	public fromResultDto(dto: PermissionResultDto): boolean {
		return Boolean(dto.permission);
	}
}
