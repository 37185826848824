import { ComponentType } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { filterNull } from '../utils/rxjs/filter-null';

/** Dialog size. */
export type DialogSize = 'extraLarge' | 'large' | 'normal' | 'extraNormal' | 'small';

/** Dialog data. */
export type DialogData = {

	/** Title. */
	readonly title: string;

	/** Size. */
	readonly size?: DialogSize;

	/** Should show required indicator or not. */
	readonly isRequired?: boolean;
};

const MAP_DIALOG_SIZE_PX: Record<DialogSize, number> = {
	small: 300,
	normal: 520,
	extraNormal: 675,
	large: 900,
	extraLarge: 1280,
};

/** Dialog service. */
@Injectable({
	providedIn: 'root',
})
export class DialogService {

	private readonly dialog = inject(MatDialog);

	/**
	 * Opens dialog.
	 * @param component Component for which dialog should be opened.
	 * @param config Dialog config.
	 * @param dialogData Dialog data.
	 */
	public openDialogWith<TComponent, TData extends DialogData, TResult = boolean>(
		component: ComponentType<TComponent>,
		dialogData?: TData,
		config?: MatDialogConfig<TData>,
	): Observable<TResult> {
		return this.dialog.open<TComponent, TData, TResult>(component, {
			data: dialogData,
			panelClass: 'custom-dialog',
			width: `${MAP_DIALOG_SIZE_PX[dialogData?.size ?? 'normal']}px`,
			...config,
		}).afterClosed()
			.pipe(filterNull());
	}
}
