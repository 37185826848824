import { z } from 'zod';

import { locationDtoSchema } from './location.dto';

/** Location dto schema. */
export const regionDtoSchema = z.object({
	id: z.number(),
	name: z.string(),
	locations: locationDtoSchema.array(),
});

/** Region dto. */
export type RegionDto = Readonly<z.infer<typeof regionDtoSchema>>;
