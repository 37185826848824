import { z } from 'zod';

/** Permission dto schema. */
export const permissionDtoSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string(),
});

/** Permission result. */
export const permissionResultDtoSchema = z.object({
	permission: z.number(),
});

/** Permission dto. */
export type PermissionDto = Readonly<z.infer<typeof permissionDtoSchema>>;

/** Permission result dto. */
export type PermissionResultDto = Readonly<z.infer<typeof permissionResultDtoSchema>>;
