import { z } from 'zod';

/** Company schema. */
export const companySchema = z.object({
	id: z.number(),
	name: z.string(),
});

/** Company. */
export type Company = Readonly<z.infer<typeof companySchema>>;
