import { Injectable, inject } from '@angular/core';

import { RegionDto } from '../dtos/region.dto';
import { Region } from '../models/region';

import { MapperFromDto } from './mappers';
import { LocationMapper } from './location.mapper';

/** Region mapper. */
@Injectable({
	providedIn: 'root',
})
export class RegionMapper implements MapperFromDto<RegionDto, Region> {
	private readonly locationMapper = inject(LocationMapper);

	/** @inheritdoc */
	public fromDto(dto: RegionDto): Region {
		return {
			id: dto.id,
			name: dto.name,
			locations: dto.locations.map(location => this.locationMapper.fromDto(location)),
		};
	}
}
