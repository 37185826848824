import { z } from 'zod';

import { locationSchema } from './location';

/** Location schema. */
export const regionSchema = z.object({
	id: z.number(),
	name: z.string(),
	locations: locationSchema.array(),
});

/** Region. */
export type Region = Readonly<z.infer<typeof regionSchema>>;
