import { z } from 'zod';

/** Job site dto schema. */
export const jobSiteDtoSchema = z.object({
	id: z.number(),
	name: z.string(),
	location_id: z.number(),
	created_at: z.string(),
	updated_at: z.string(),
});

/** Job site dto. */
export type JobSiteDto = Readonly<z.infer<typeof jobSiteDtoSchema>>;
