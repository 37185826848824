<div
	class="checkboxes-list"
	[class.checkboxes-list_vertical]="orientation === 'vertical'"
>
	@for (option of options; track option.value) {
		<label class="checkbox-label">
			<input
				[checked]="isValueChecked(option.value)"
				(change)="onCheck($event)"
				[value]="option.value"
				type="checkbox"
				class="checkbox"
			/>
			{{ option.label }}
		</label>
	}
</div>
