import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DialogData } from '@mei/common/core/services/dialog.service';
import { BehaviorSubject } from 'rxjs';

/** Dialog body id in DOM. */
export const DIALOG_BODY_ID = 'dialog-body';

/** Dialog component. */
@Component({
	selector: 'meic-dialog',
	templateUrl: './dialog.component.html',
	styleUrl: './dialog.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatIconModule, CommonModule, ScrollingModule],
})
export class DialogComponent<T extends DialogData = DialogData> {
	/** Dialog title. */
	@Input()
	public set title(newTitle: string | null) {
		if (newTitle == null) {
			return;
		}
		this.title$.next(newTitle);
	}

	/** Should include body styles. */
	@Input()
	public includeBodyStyles = true;

	/** Dialog body id. */
	protected readonly dialogBodyId = DIALOG_BODY_ID;

	/** Dialog ref. */
	protected readonly dialogRef = inject(MatDialogRef<DialogComponent<T>>);

	/** Dialog data. */
	protected readonly dialogData = inject<T>(MAT_DIALOG_DATA);

	/** Dialog title. */
	protected readonly title$ = new BehaviorSubject(this.dialogData.title);

	/** Handles click on cancel button. */
	protected onCancelButtonClick(): void {
		this.dialogRef.close(false);
	}

	/**
	 * Close dialog with result value.
	 * @param result Result value. Default boolean.
	 * False means user closed dialog without any action. True means user did the action successfully.
	 */
	protected closeDialogWithResult<K = boolean>(result: K): void {
		this.dialogRef.close(result);
	}
}
