import { Injectable } from '@angular/core';

import { JobSiteDto } from '../dtos/quote/job-site.dto';
import { JobSite } from '../models/quote/job-site';

import { MapperFromDto } from './mappers';

/** Job site mapper. */
@Injectable({
	providedIn: 'root',
})
export class JobSiteMapper implements MapperFromDto<JobSiteDto, JobSite> {
	/** @inheritdoc */
	public fromDto(dto: JobSiteDto): JobSite {
		return {
			id: dto.id,
			name: dto.name,
			locationId: dto.location_id,
			createdAt: new Date(dto.created_at),
			updatedAt: new Date(dto.updated_at),
		};
	}
}
