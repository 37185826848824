import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Option } from '@mei/common/core/models/option';
import { SimpleValueAccessor, controlProviderFor } from '@mei/common/core/utils/value-accessor';

type CheckboxValue = string | number;

/** Checkbox select component. */
@Component({
	selector: 'meic-checkbox-select',
	templateUrl: './checkbox-select.component.html',
	styleUrls: ['./checkbox-select.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	providers: [controlProviderFor(() => CheckboxSelectComponent)],
	imports: [CommonModule],
})
export class CheckboxSelectComponent extends SimpleValueAccessor<CheckboxValue[]> {

	/** Options. */
	@Input()
	public options: Option[] = [];

	/** Checkboxes orientation. */
	@Input()
	public orientation: 'horizontal' | 'vertical' = 'horizontal';

	/** Whether value is numeric type. */
	@Input()
	public numeric: boolean | null = false;

	/**
	 * Handles checkbox change.
	 * @param event Checkbox change event.
	 */
	protected onCheck(event: Event): void {
		const target = event.target as HTMLInputElement;
		const currentValue = this.controlValue !== null ? [...this.controlValue] : [];
		const value = this.numeric ? Number(target.value) : target.value;

		if (target.checked) {
			this.controlValue = currentValue.concat(value);
		} else if (currentValue.includes(value)) {
			const index = currentValue.indexOf(value);
			currentValue.splice(index, 1);
			this.controlValue = currentValue;
		}
	}

	/**
	 * Whether the value is checked or not.
	 * @param value Value to check.
	 */
	protected isValueChecked(value: CheckboxValue): boolean {
		return this.controlValue?.includes(value) ?? false;
	}
}
