import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const DEFAULT_SPINNER_SIZE = 50;

type LoadingMode = 'bar' | 'spinner';

/** Loading component. */
@Component({
	selector: 'meic-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [MatProgressSpinnerModule, NgIf],
})
export class LoadingComponent {

	/** Spinner diameter size. */
	@Input()
	public size = DEFAULT_SPINNER_SIZE;

	/** Loading for skeleton. */
	@Input()
	public forSkeleton = false;

	/** Loading mode. */
	@Input()
	public mode: LoadingMode = 'spinner';
}
